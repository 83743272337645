import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getCommandsWithStatusAndNumber = createAsyncThunk('command/getCommandsWithStatusAndNumber', async ({ period, cabinet }, ThunkApi) => {
    try {
        const query = queryString.stringify({ period, cabinet });
        const response = await apiKey.get(`/commands/status-number?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getCommands = createAsyncThunk('command/getCommands', async ({ limit, page, archived, startDate, endDate, startLimit, endLimit, startLivraison, endLivraison, }, ThunkApi) => {
    try {
        const query = queryString.stringify(startDate && endDate
            ? {
                archived,
                limit,
                page,
                startDate,
                endDate,
                startLimit,
                endLimit,
                startLivraison,
                endLivraison,
            }
            : { archived, limit, page, startLimit, endLimit, startLivraison, endLivraison });
        const response = await apiKey.get(`/commands?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getAllTypesOfCommand = createAsyncThunk('command/getAllTypesOfCommand', async ({ limit, page, status }, ThunkApi) => {
    try {
        const query = queryString.stringify(status === 'draft' ? { limit, page } : { limit, page, status });
        const response = status === 'draft'
            ? await apiKey.get(`/protheses?${query}`)
            : await apiKey.get(`/commands?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getSearchedCommands = createAsyncThunk('command/getSearchedCommands', async ({ search, limit, page, archived, startDate, endDate, startLimit, endLimit, startLivraison, endLivraison, }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search
            ? {
                limit,
                page,
                archived,
                startDate,
                endDate,
                startLimit,
                endLimit,
                startLivraison,
                endLivraison,
            }
            : startDate && endDate
                ? {
                    search,
                    limit,
                    page,
                    archived,
                    startDate,
                    endDate,
                    startLimit,
                    endLimit,
                    startLivraison,
                    endLivraison,
                }
                : { search, limit, page, archived, startLimit, endLimit, startLivraison, endLivraison });
        const response = await apiKey.get(`/commands/search?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getLatestCommands = createAsyncThunk('command/getLatestCommands', async ({ limit, page, period }, ThunkApi) => {
    try {
        const query = queryString.stringify({ period, limit, page });
        const response = await apiKey.get(`/commands/latest-commands?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteCommand = createAsyncThunk('command/deleteCommand', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.delete(`/commands/${id}`);
        const data = response.data.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateCommand = createAsyncThunk('command/updateCommand', async ({ params, id }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/commands/${id}`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const accpetCommand = createAsyncThunk('command/accpetCommand', async ({ params, id }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/commands/assign-status/${id}`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const archiveCommand = createAsyncThunk('command/archiveCommand', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.post(`/commands/${id}/archive`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const unArchiveCommand = createAsyncThunk('command/unArchiveCommand', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.post(`/commands/${id}/unarchive`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const printCommand = createAsyncThunk('command/printCommand', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/pdf/etiquette`, params, {
            responseType: 'blob',
        });
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = `commande n°${params?.commandId}.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getCommandById = createAsyncThunk('treatment/getCommandById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/commands/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
